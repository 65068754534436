import { getFiliation, setFiliation } from "@/taskpane/services/filiation";
import { MutationOptions, useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";

export const useGetFiliationQuery = (
  folderId: string,
  subFolderId: string,
  opt?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFiliation>>>>
) =>
  useQuery({
    ...opt,
    queryFn: () => getFiliation({ folderId, subFolderId }),
    queryKey: ["getFiliation"],
  });

export const useSetFiliationMutation = (
  opt?: MutationOptions<Awaited<ReturnType<typeof setFiliation>>, Error, Parameters<typeof setFiliation>[0]>
) => {
  return useMutation({
    ...opt,
    mutationFn: setFiliation,
  });
};
