import { Property, ValidatedProperty } from "@/taskpane/types/filiation";
import { apiClient } from "@/taskpane/utils/client";
import { handleError } from "@/taskpane/utils/errors";

interface ErrorWithStatus extends Error {
  status?: number;
}

/**
 * Retrieves the filiation data for a given folder and subfolder.
 *
 * @param {Object} options - The options for retrieving the filiation data.
 * @param {string} options.folderId - The ID of the folder.
 * @param {string} options.subFolderId - The ID of the subfolder.
 * @returns {Promise<Property[]>} - A promise that resolves to an array of filiation properties.
 */
export async function getFiliation({
  folderId,
  subFolderId,
}: {
  folderId: string;
  subFolderId: string;
}): Promise<Property[] | null> {
  try {
    const response = await apiClient.get(`/dossier/${folderId}/filiation/${subFolderId}`);
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /dossier/${folderId}/filiation/${subFolderId} failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Aucun dossier ou flux de travail n'a été trouvé`);
  }
}

/**
 * Sets the filiation for a given folder and subfolder.
 *
 * @param {Object} options - The options for setting the filiation.
 * @param {string} options.folderId - The ID of the folder.
 * @param {string} options.subFolderId - The ID of the subfolder.
 * @param {ValidatedProperty[]} options.properties - The properties to set for the filiation.
 * @returns {Promise<void>} - A promise that resolves when the filiation is set.
 */
export async function setFiliation({
  folderId,
  subFolderId,
  properties,
}: {
  folderId: string;
  subFolderId: string;
  properties: ValidatedProperty[];
}) {
  try {
    const response = await apiClient.post(`/dossier/${folderId}/filiation/${subFolderId}`, properties);
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `POST request to api endpoint /dossier/${folderId}/filiation/${subFolderId} failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Echec de la mise à jour des propriétés du sous-dossier ${subFolderId}`);
  }
}
