import * as React from "react";

import { Outlet } from "react-router-dom";

import { Header } from "./header";
import { Box } from "@mui/material";

export function Layout() {
    return (
        <Box height="100vh" sx={{ px: 1 }}>
            <Header />
            <Outlet />
        </Box>
    )
}