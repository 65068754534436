import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FixedSizeList } from "react-window";
import { useNavigate } from "react-router-dom";

import { Folder } from "@/taskpane/types/folder";
import { useQuery } from "@tanstack/react-query";
import { CloseOutlined } from "@mui/icons-material";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import { getAttachments } from "@/taskpane/utils/attachments";
import { useGetUserDataQuery } from "@/taskpane/services/user.hook";
import { useGetFoldersQuery } from "@/taskpane/services/folders.hook";
import { alpha, Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, List, ListItemButton, Skeleton, Stack, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useOfficeContext } from "@/taskpane/contexts/office/office-context";

/* global Office */

/**
 * Renders a single folder item in the folders list.
 *
 * @param folder - The folder object to render.
 * @param onClick - The click event handler for the folder item.
 * @param selected - Indicates whether the folder item is selected.
 */
function FolderItem({ folder, onClick, selected }: { folder: Folder; onClick: () => void; selected: boolean }) {
  const theme = useTheme();
  return (
    <ListItemButton
      sx={{
        flexDirection: "column",
        height: 50,
        px: 1,
        "&.Mui-selected, &.Mui-selected:hover": {
          bgcolor: alpha(theme.palette.secondary.main, 0.5),
        },
      }}
      onClick={onClick}
      selected={selected}
    >
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
        <Tooltip title={folder.name.length > 30 ? folder.name : ""}>
          <Typography fontWeight="bold" fontSize={11} noWrap>
            {folder.name}
          </Typography>
        </Tooltip>
        <Typography color="black" sx={{ opacity: 0.5 }} fontWeight="bold" fontSize={11}>
          {folder.dossier_id}
        </Typography>
      </Stack>
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
        <Typography noWrap fontSize={10} fontWeight="bold" color="black" sx={{ opacity: 0.5 }}>
          Créé le {folder.created_at.toLocaleDateString()} ({folder.intervenants_notaires.join("/")})
        </Typography>
        <Typography color="black" sx={{ opacity: 0.5 }} fontWeight="bold" fontSize={12}>
          {folder.status === "en_cours" ? "En cours" : "Terminé"}
        </Typography>
      </Stack>
    </ListItemButton>
  );
}

function NoFolders() {
  return (
    <Typography fontWeight="bold" color="grey.400" textAlign="center" p={3}>
      Aucun dossier correspondant à ces critères n’a été trouvé
    </Typography>
  );
}

const listStyle = {
  backgroundColor: "white",
  border: "solid 1px",
  borderColor: "rgba(135, 135, 135, 0.34)",
  borderRadius: "4px",
  padding: 0,
};

export default function FolderList() {
  const [search, setSearch] = useState("");
  const [showMyFolders, setShowMyFolders] = useState(false);
  const [showInProgress, setShowInProgress] = useState(false);
  const { data: folders, isLoading } = useGetFoldersQuery();
  const { data: userData } = useGetUserDataQuery();
  const [selectedFolder, setSelectedFolder] = useState<Folder>();
  const navigate = useNavigate();
  const [currentEmailId, setCurrentEmailId] = useState<string | undefined>(undefined);
  const [hasAttachments, setHasAttachments] = useState(false);
  const { data: dataAttachments, refetch } = useQuery({
    queryKey: ["getAttachments", currentEmailId],
    queryFn: getAttachments,
    enabled: !!currentEmailId,
  });
  const { changeCounter, currentMailbox } = useOfficeContext();

  /**
   * Updates the current email ID based on the Office context mailbox item.
   * @returns {Promise<void>} A promise that resolves when the current email ID is updated.
   */
  const updateCurrentEmail = useCallback(async () => {
    if (currentMailbox?.item) {
      const newEmailId = currentMailbox.item.itemId;
      setCurrentEmailId(newEmailId);
    }
  }, [setCurrentEmailId, currentMailbox]);

  useEffect(() => {
    updateCurrentEmail();
    const intervalId = setInterval(() => {
      updateCurrentEmail();
    }, 5000); // Check for updates every 5 seconds
    return () => clearInterval(intervalId);
  }, [updateCurrentEmail]);

  useEffect(() => {
    if (currentEmailId) {
      refetch();
    }
  }, [currentEmailId, refetch]);

  useEffect(() => {
    setHasAttachments(!!dataAttachments && dataAttachments?.attachments && dataAttachments?.attachments?.length > 0);
  }, [dataAttachments]);

  useEffect(() => {
    updateCurrentEmail()
  }, [changeCounter, currentMailbox]);

  /**
   * Filters the folders based on the provided criteria.
   *
   * @param {Folder[]} folders - The list of folders to filter.
   * @param {boolean} showMyFolders - Indicates whether to show only the user's folders.
   * @param {boolean} showInProgress - Indicates whether to show only folders in progress.
   * @param {string} search - The search term to filter folders by name or dossier ID.
   * @param {UserData} userData - The user data containing information about the user.
   * @returns {Folder[]} - The filtered list of folders.
   */
  const filteredFolders = useMemo(
    () =>
      (folders ?? ([] as Folder[])).filter((folder) => {
        const matchesSearch =
          folder.name.toLowerCase().includes(search.toLowerCase()) ||
          folder.dossier_id.toLowerCase().includes(search.toLowerCase());
        const matchesMyFolders =
          !showMyFolders ||
          (userData?.user_info?.initiales_inot &&
            folder?.dossier_intervenants_notaire.includes(userData.user_info.initiales_inot));
        const matchesInProgress = !showInProgress || folder.status === "en_cours";
        return matchesSearch && matchesMyFolders && matchesInProgress;
      }),
    [folders, showMyFolders, showInProgress, search, userData]
  );

  useEffect(() => {
    if (!selectedFolder) {
      return;
    }
    if (!filteredFolders.find((f) => f.dossier_id === selectedFolder.dossier_id)) {
      setSelectedFolder(undefined);
    }
  }, [selectedFolder, filteredFolders]);

  /**
   * Renders a row in the FoldersList component. Essential for the react-window FixedSizeList component.
   *
   * @param index - The index of the row.
   * @param style - The CSS styles to apply to the row (generated by react-window).
   * @returns The JSX element representing the row.
   */
  const Row = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      if (isLoading) {
        return (
          <div style={style}>
            <ListItemButton sx={{ opacity: 1 - index * 0.1 }}>
              <Stack spacing={0.3} width="100%">
                <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                  <Skeleton animation="wave" variant="text" width="60%" height={20} />
                  <Skeleton animation="wave" variant="text" width="20%" height={20} />
                </Stack>
                <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                  <Skeleton animation="wave" variant="text" width="40%" height={14} />
                  <Skeleton animation="wave" variant="text" width="12%" height={16} />
                </Stack>
              </Stack>
            </ListItemButton>
            <Divider sx={{ width: "60%", mx: "auto", borderColor: "grey.300", opacity: 1 - index * 0.1 }} />
          </div>
        );
      }

      const folder = filteredFolders[index];
      return (
        <div style={style}>
          <FolderItem
            folder={folder}
            onClick={() => setSelectedFolder(folder)}
            selected={folder.dossier_id === selectedFolder?.dossier_id}
          />
        </div>
      );
    },
    [filteredFolders, isLoading, selectedFolder]
  );

  return (
    <Stack p={0} gap={1} height={0.92}>

      {/* <Logo /> */}

      <Box sx={{ mt: 1 }}>
        <TextField
          sx={{ bgcolor: "white" }}
          fullWidth
          size="small"
          variant="outlined"
          placeholder="Rechercher un dossier iNot..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: search && (
              <IconButton size="small" onClick={() => setSearch("")}>
                <CloseOutlined />
              </IconButton>
            ),
          }}
        />
        <Stack pl={1.5} mt={0.5} direction="row">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showMyFolders}
                color="primary"
                onChange={(e) => setShowMyFolders(e.target.checked)}
                sx={{ mr: 0.5 }}
              />
            }
            label="Mes dossiers"
            slotProps={{ typography: { fontSize: 12 } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="primary"
                checked={showInProgress}
                onChange={(e) => setShowInProgress(e.target.checked)}
                sx={{ mr: 0.5 }}
              />
            }
            label="En cours"
            slotProps={{ typography: { fontSize: 12 } }}
          />
        </Stack>
      </Box>

      {(isLoading || !!filteredFolders.length) && (
        <FixedSizeList
          height={window.innerHeight - 200}
          itemSize={50}
          itemCount={isLoading ? 8 : filteredFolders.length}
          width="100%"
          style={listStyle}
        >
          {Row}
        </FixedSizeList>
      )}
      {!isLoading && !filteredFolders.length && (
        <List
          sx={{
            ...listStyle,
            height: "100%",
          }}
        >
          <NoFolders />
        </List>
      )}
      <Grid container spacing={0.7}>
        <Grid item xs={4}>
          <WarningTooltip
            title={
              !selectedFolder
                ? "Vous devez sélectionner un dossier"
                : hasAttachments ? "" : "Aucune pièce jointe disponible"
            }
          >
            <span>
              <Button
                size="large"
                fullWidth
                disabled={!selectedFolder || !hasAttachments}
                onClick={() => navigate(`/folder/${selectedFolder?.dossier_id}/attachment`)}
              >
                Pièces jointes
              </Button>
            </span>
          </WarningTooltip>
        </Grid>
        <Grid item xs={4}>
          <WarningTooltip title={!selectedFolder ? "Vous devez sélectionner un dossier" : ""}>
            <span>
              <Button
                size="large"
                fullWidth
                disabled={!selectedFolder}
                onClick={() => navigate(`/folder/${selectedFolder?.dossier_id}/drag-and-drop`)}
              >
                Glissez-déposez
              </Button>
            </span>
          </WarningTooltip>
        </Grid>
        <Grid item xs={4}>
          <WarningTooltip title={!selectedFolder ? "Vous devez sélectionner un dossier" : ""}>
            <span>
              <Button
                size="large"
                fullWidth
                disabled={!selectedFolder}
                onClick={() => navigate(`/folder/${selectedFolder?.dossier_id}/notary-space`)}
              >
                Espace-notarial
              </Button>
            </span>
          </WarningTooltip>
        </Grid>
      </Grid>
    </Stack>
  );
}
